import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/table/Table.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const InternalContent = makeShortcode("InternalContent");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/table" storybook="https://workday.github.io/canvas-kit/?path=/story/table--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=20263-33834&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy---tables"
      }}>{`Anatomy - Tables`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/50e88f5b6c83047866ab58b7dc7bb073/a3a74/component-anatomy-table.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "44.5945945945946%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA/klEQVR42o1Si26DMBDr//8oW0uBAHk/vHNCCpW2qSddhGPHMQc3sFIGXEQMESkllFJwreKCLO97f9WtrsYjjQpfw4Dv+x3O+cOpmeRhaZd+bKg9ymOFmhUe4xNq3WDFdF4UntOMbdthrMM0L5gEa2Oxa1M5asjlnC+GTCKvqkW0ilnwoQr2fRezDTG2UfCZe+RCCFVrjKm4j+lMOG5wcjMFPMyy1tammE2OuI5dNMZoeO+PTFfDJCCkKtZaI0mibtATsMkRs5ia2Dn3uvA0PIpkN/gkIQ1/T3iU4bBlRv3X4QF2T0iOuCck/jdhP9gETNFx+24n3xI1XF48DX8AzGbD4KrNmRAAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of the anatomy of a Table.",
              "title": "Example of the anatomy of a Table.",
              "src": "/static/50e88f5b6c83047866ab58b7dc7bb073/50383/component-anatomy-table.png",
              "srcSet": ["/static/50e88f5b6c83047866ab58b7dc7bb073/1efb2/component-anatomy-table.png 370w", "/static/50e88f5b6c83047866ab58b7dc7bb073/50383/component-anatomy-table.png 740w", "/static/50e88f5b6c83047866ab58b7dc7bb073/a3a74/component-anatomy-table.png 2272w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Table Header (Optional)`}</strong>{`: May consist of a header, result count, optional subheading, or any
custom configuration of text.`}</li>
        <li parentName="ol"><strong parentName="li">{`Table Outline (Optional)`}</strong>{`: A border is used by default, but may be omitted for a full page
table or in cases where a table is the sole element on a page.`}</li>
        <li parentName="ol"><strong parentName="li">{`Column Header`}</strong>{`: Include just column labels by default, but icon actions for actions such as
sort and filter may be added. Note that this functionality will be a custom add for developers.`}</li>
        <li parentName="ol"><strong parentName="li">{`Cell`}</strong>{`: May consist of text, icons, status indicators, buttons, data types, or other custom
content. Text overflows by default, but teams may elect to apply truncation with a tooltip.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <p>{`Data Tables are intended to display data that can be easily scanned and compared.`}</p>
      <ul>
        <li parentName="ul">{`Conceptually, each row in a Table represents an item, and each cell of that row is an attribute of
that item.`}</li>
        <li parentName="ul">{`This means that all the cells in a particular column will be the same data type such as dates,
numerals, text, etc.`}</li>
        <li parentName="ul">{`Ideally, there should be one value per cell. Field sets are discouraged.`}</li>
        <li parentName="ul">{`Nested Tables are highly discouraged.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <p>{`Use tables to allow users to:`}</p>
      <ul>
        <li parentName="ul">{`Easily scan and compare data`}</li>
        <li parentName="ul">{`View and edit data`}</li>
        <li parentName="ul">{`Manipulate and navigate through a large amount of data`}</li>
        <li parentName="ul">{`Preview data`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <p>{`Consider another component when:`}</p>
      <ul>
        <li parentName="ul">{`You only have a small data set.`}</li>
        <li parentName="ul">{`A more detailed amount of information needs to be displayed by default.`}</li>
        <li parentName="ul">{`There is more than one piece of information within a cell.`}</li>
      </ul>
      <InternalContent mdxType="InternalContent">
        <h3 {...{
          "id": "canvas-table-vs-tables-20"
        }}>{`Canvas Table vs. Tables 2.0`}</h3>
        <p>{`The Canvas Table provides a simple, semantic table with some basic styling while Tables 2.0 provides
a more fully featured table experience.`}</p>
        <p>{`The Canvas Table is intended for use cases with view only data, simpler data sets, or custom
styling. It doesn't provide functionality such as search, filter, sort, etc out of the box; teams
will need to customize it to fit their use cases. The Canvas Table is intended for Bespoke
development and cannot be used with XO.`}</p>
        <p>{`Tables 2.0 provides much more functionality out of the box, including column sorting & filtering,
column resizing, pagination, etc. In addition, Tables 2.0 is configured to be used both in Bespoke
and XO development. For uptake and usage details, please refer to the
`}<a parentName="p" {...{
            "href": "https://docs.workday.build/UIC/riva-client/"
          }}>{`Tables 2.0 documentation site`}</a>{`.`}</p>
      </InternalContent>
      <h3 {...{
        "id": "dos-and-donts"
      }}>{`Dos and Don'ts`}</h3>
      <SideBySide mdxType="SideBySide">
        <Suggestion status="do" guidance="Right align numeric data in tables." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "740px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/051474ece069086fdbbde283d6738862/c1b63/table-do-1.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "53.783783783783775%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image displaying a table of expense items and a last column of left aligned numbers.",
                  "title": "Image displaying a table of expense items and a last column of left aligned numbers.",
                  "src": "/static/051474ece069086fdbbde283d6738862/50383/table-do-1.png",
                  "srcSet": ["/static/051474ece069086fdbbde283d6738862/1efb2/table-do-1.png 370w", "/static/051474ece069086fdbbde283d6738862/50383/table-do-1.png 740w", "/static/051474ece069086fdbbde283d6738862/c1b63/table-do-1.png 1200w"],
                  "sizes": "(max-width: 740px) 100vw, 740px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
        <Suggestion status="do" guidance="Right align actions in tables." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "740px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/77594e97d36cd3ae2fc6fc79e526cec1/c1b63/table-do-2.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "53.783783783783775%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image displaying a table of exceptions and a last column of left aligned action buttons.",
                  "title": "Image displaying a table of exceptions and a last column of left aligned action buttons.",
                  "src": "/static/77594e97d36cd3ae2fc6fc79e526cec1/50383/table-do-2.png",
                  "srcSet": ["/static/77594e97d36cd3ae2fc6fc79e526cec1/1efb2/table-do-2.png 370w", "/static/77594e97d36cd3ae2fc6fc79e526cec1/50383/table-do-2.png 740w", "/static/77594e97d36cd3ae2fc6fc79e526cec1/c1b63/table-do-2.png 1200w"],
                  "sizes": "(max-width: 740px) 100vw, 740px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
      </SideBySide>
      <SideBySide mdxType="SideBySide">
        <Suggestion status="do" guidance="Allow cell text to wrap where possible, giving users access to the full contents of the cell by default." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "740px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/a11f92c7c5b54a7f708da469d9da2f5f/1dbe8/table-do-3.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "57.2972972972973%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image displaying a table of native tree types and a comments column with text that wraps.",
                  "title": "Image displaying a table of native tree types and a comments column with text that wraps.",
                  "src": "/static/a11f92c7c5b54a7f708da469d9da2f5f/50383/table-do-3.png",
                  "srcSet": ["/static/a11f92c7c5b54a7f708da469d9da2f5f/1efb2/table-do-3.png 370w", "/static/a11f92c7c5b54a7f708da469d9da2f5f/50383/table-do-3.png 740w", "/static/a11f92c7c5b54a7f708da469d9da2f5f/1dbe8/table-do-3.png 1374w"],
                  "sizes": "(max-width: 740px) 100vw, 740px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
        <Suggestion status="do" guidance="We recommend wrapping text where possible, but if needed, cell text may be truncated, showing full text in a tooltip on hover." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "740px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/986e4abcf9046224e8546d23e8008c96/1dbe8/table-caution.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "63.24324324324324%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image displaying a table of planetary stats, with a cell that is truncated and shows a tooltip with the full text.",
                  "title": "Image displaying a table of planetary stats, with a cell that is truncated and shows a tooltip with the full text.",
                  "src": "/static/986e4abcf9046224e8546d23e8008c96/50383/table-caution.png",
                  "srcSet": ["/static/986e4abcf9046224e8546d23e8008c96/1efb2/table-caution.png 370w", "/static/986e4abcf9046224e8546d23e8008c96/50383/table-caution.png 740w", "/static/986e4abcf9046224e8546d23e8008c96/1dbe8/table-caution.png 1374w"],
                  "sizes": "(max-width: 740px) 100vw, 740px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
      </SideBySide>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "how-tables-impact-the-accessible-experience"
      }}>{`How Tables Impact The Accessible Experience`}</h3>
      <p>{`Whether you are comparing products or understanding a schedule, arranging information into rows and
columns helps communicate relationships in the content. When users are unable to perceive the visual
layout of the content, screen readers are able to derive context like the number of rows in a table
from the number of `}<inlineCode parentName="p">{`<tr>`}</inlineCode>{` elements. Similarly, the number of columns is based on the `}<inlineCode parentName="p">{`<th>`}</inlineCode>{` and
`}<inlineCode parentName="p">{`<td>`}</inlineCode>{` elements used in a row.`}</p>
      <p>{`Semantic, and correct, HTML markup is critical to providing access to tabular content. On the other
hand, table markup used strictly for the purposes of content layout may be invisible to sighted
users, but screen readers will continue to express the content in terms of rows and columns. This
dated practice can make it much more difficult for non-visual users to understand simple content on
a page.`}</p>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Any interactive elements in the Table must have a focus indicator that is highly visible against the
background and against the non-focused state. Refer to Accessible Colors for more information.`}</p>
      <p>{`Tables must support the following keyboard interactions: `}<inlineCode parentName="p">{`Tab`}</inlineCode>{`: Focuses interactive elements
included inside the table cells (e.g. buttons, links, inputs, selects, etc.) `}<inlineCode parentName="p">{`Left Arrow`}</inlineCode>{` or
`}<inlineCode parentName="p">{`Right Arrow`}</inlineCode>{`:Scroll the table with fixed columns`}</p>
      <blockquote>
        <p parentName="blockquote"><strong parentName="p"><em parentName="strong">{`NOTE:`}</em></strong>{` The keyboard should only be allowed to focus on the interactive components inside of a
table. Allowing the keyboard to focus on the individual rows of the table is not recommended.`}</p>
      </blockquote>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Tables must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`The purpose of the table`}</li>
        <li parentName="ul">{`The correct number of rows and columns in each table`}</li>
        <li parentName="ul">{`Automatically describe the column headers when reading content from any row in the table`}</li>
        <li parentName="ul">{`Automatically describe row headers when reading content from any column in the table (if
applicable)`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`Specify the column header for every column`}</li>
        <li parentName="ul">{`Specify the row headers, if applicable`}</li>
        <li parentName="ul">{`Specify the caption of the table`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`Do not use any table markup strictly for layout purposes.`}</li>
        <li parentName="ul">{`Set the `}<inlineCode parentName="li">{`scope`}</inlineCode>{` attribute to `}<inlineCode parentName="li">{`”col”`}</inlineCode>{` on the column header `}<inlineCode parentName="li">{`<th>`}</inlineCode>{` elements.`}</li>
        <li parentName="ul">{`Set the `}<inlineCode parentName="li">{`scope`}</inlineCode>{` attribute to `}<inlineCode parentName="li">{`”row”`}</inlineCode>{` on the row header `}<inlineCode parentName="li">{`<th>`}</inlineCode>{` elements, if applicable.`}</li>
        <li parentName="ul">{`When a Table `}<inlineCode parentName="li">{`<caption>`}</inlineCode>{` is not used, set an `}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{` attribute on the `}<inlineCode parentName="li">{`<table>`}</inlineCode>{` element
referencing the unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` of the table’s heading.`}</li>
        <li parentName="ul">{`When a table can scroll horizontally with fixed columns, set `}<inlineCode parentName="li">{`tabindex=”0”`}</inlineCode>{` on the `}<inlineCode parentName="li">{`<table>`}</inlineCode>{`
element.`}</li>
        <li parentName="ul">{`[Included in component]`}{` The `}<inlineCode parentName="li">{`<caption>`}</inlineCode>{` element must be used to briefly describe the purpose of a
table.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`When writing titles, column headers, and other text for Tables, refer to the
`}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/grids"
          }}>{`Tables`}</a>{` section of the Content Style Guide.`}</li>
      </ul>
      <h2 {...{
        "id": "mobile"
      }}>{`Mobile`}</h2>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Platform`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Availability`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`iOS`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Yes`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Android`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Yes`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "guidelines"
      }}>{`Guidelines`}</h3>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Due to screen size limitations, the display of Tables on mobile is different from the desktop
display. Rather than displaying inline on the page, there is a tile preview which the user can tap
to display a full-page view-only Table. To edit the data in this Table, the user taps the row they
wish to edit and an editable form of that row is shown.`}</p>
          <p parentName="li"><strong parentName="p">{`Please note:`}</strong>{` Tables 2.0 is not currently available for Mobile applications.`}</p>
        </li>
      </ul>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      